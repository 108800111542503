import React, { CSSProperties, FC, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { ChevronDown, Copy } from "@qwilr/kaleidoscope/icons";
import * as KLIcons from "@qwilr/kaleidoscope/icons";
import * as Kaleidoscope from "@qwilr/kaleidoscope";
import Highlight, { defaultProps, Language } from "prism-react-renderer";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import prismTheme from "../../utils/prismTheme";
import usePrevious from "../../hooks/usePrevious";
import * as mobx from "mobx";
import { useLayoutEffect } from "@qwilr/kaleidoscope/utils";
import { AnimationDuration, Stack, StackProps } from "@qwilr/kaleidoscope";
import { tokens, darkTheme } from "@qwilr/kaleidoscope/tokens";

const { ButtonTheme, ButtonType, IconButton, Button, CopyToClipboard, ButtonSize, Label } = Kaleidoscope;
const { ChevronRight, Add, IconSize, Team } = KLIcons;

interface IPostCodeProps {
  className?: string;
  children: string;
}

const MAX_HEIGHT = 300;

const PostCode: FC<IPostCodeProps> = ({ className = "language-jsx", children }) => {
  const [expanded, setExpanded] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [codeHeight, setCodeHeight] = useState<number>(0);
  const codeRef = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLDivElement>();
  const prevExpanded = usePrevious(expanded);

  const params = className.split("-");
  const isLive = params.includes("live");
  const language = params[1] as Language;
  const isColumnLayout = params.includes("column");
  const code = useMemo(() => children.slice(0, children.lastIndexOf("\n")), [children]);

  const currentCodeHeight = expanded ? codeHeight : Math.min(MAX_HEIGHT, codeHeight);

  useEffect(() => {
    if (!codeRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (!codeRef.current) return;

      const { height } = codeRef.current?.getBoundingClientRect();
      setCodeHeight(height);
    });

    resizeObserver.observe(codeRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useLayoutEffect(() => {
    const handleTransitionEnd = () => {
      setTransitioning(false);
    };

    if (prevExpanded !== undefined && expanded !== prevExpanded) {
      setTransitioning(true);
      contentRef.current?.addEventListener("transitionend", handleTransitionEnd);
    }

    return () => {
      contentRef.current?.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [expanded]);

  useEffect(() => {
    if (isLive) {
      codeRef.current?.querySelector("textarea")?.setAttribute("rows", "1");
    }
  }, [isLive]);

  const renderActions = () => (
    <div className="kld-code__actions">
      <CopyToClipboard value={children}>
        {({ onCopy }) => <IconButton icon={<Copy />} onClick={onCopy} theme={ButtonTheme.Dark} />}
      </CopyToClipboard>
    </div>
  );

  return (
    <div className="kld-code">
      {!isLive && (
        <div
          className={classNames("kld-code__content", {
            "kld-code__content--expanded": expanded,
            "kld-code__content--transitioning": transitioning,
          })}
          style={{ "--height": `${currentCodeHeight}px` } as CSSProperties}
          ref={contentRef}
        >
          {renderActions()}
          <code className="kld-code__element" ref={codeRef}>
            <Highlight {...defaultProps} code={code} language={language} theme={prismTheme}>
              {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <pre className={className} style={style}>
                  {tokens.map((line, i) => (
                    <div {...getLineProps({ line, key: i })}>
                      {line.map((token, key) => (
                        <span {...getTokenProps({ token, key })} />
                      ))}
                    </div>
                  ))}
                </pre>
              )}
            </Highlight>
          </code>
        </div>
      )}

      {isLive && (
        <LiveProvider
          code={code}
          scope={{ ...KLIcons, ...Kaleidoscope, mobx, AnimationDuration, ExampleContent, tokens }}
          theme={prismTheme}
          transformCode={transformLiveCode}
        >
          <LivePreview className={classNames("kld-code__preview", { "kld-code__preview--column": isColumnLayout })} />
          <div
            className={classNames("kld-code__content", darkTheme, {
              "kld-code__content--expanded": expanded,
              "kld-code__content--transitioning": transitioning,
            })}
            style={{ "--height": `${currentCodeHeight}px` } as CSSProperties}
            ref={contentRef}
          >
            {renderActions()}
            <code className="kld-code__element" ref={codeRef}>
              <Label className="kld-code__label" element="div">
                Editable example
              </Label>
              <LiveEditor className="kld-code__editor-content" />
            </code>
          </div>
          <LiveError className="kld-code__error" />
        </LiveProvider>
      )}
      {codeHeight > MAX_HEIGHT && (
        <div className={classNames("kld-code__footer", darkTheme, { "kld-code__footer--expanded": expanded })}>
          <div className="kld-code__footer-content">
            <Button
              type={ButtonType.Tertiary}
              theme={ButtonTheme.Dark}
              size={ButtonSize.Small}
              onClick={() => setExpanded(!expanded)}
            >
              <ChevronDown />
              {expanded ? "Show less" : "Show more"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

function transformLiveCode(code: string) {
  if (code[0] === "<") {
    return `
      <React.Fragment>
        ${code}
      </React.Fragment>
    `;
  }
  return code;
}

const ExampleContent = ({ style, ...props }: StackProps) => (
  <Stack
    style={{
      background: tokens.color.surfaceSecondary,
      border: `1px solid ${tokens.color.border}`,
      borderRadius: tokens.borderRadius.surface,
      ...style,
    }}
    {...props}
  />
);

export default PostCode;
